/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
 --border-radius:12px;
}

$theme-colors: (
    "primary": #332d37,
    "secondary": #e9d9ea,
    "secondary-light": #fbf7fb,
    "blue": #86cdff,
    "blue-light": #DAECFD,
    "red": #b32e27,
    "red-light": #F6DEDF,
    "yellow": #f0d800,
    "yellow-light": #FDF9D9,
    "green": #9DE079,
    "green-validation": #5ec011,
    "green-light": #f0fadf,
    "success": #9DE079
);

$blue: #85ccff;
$input-border-color: $blue;
$input-border-radius: 12px;
$input-border-radius-sm: 12px;
$input-color: theme-color("primary");
$component-active-bg: $blue;
$input-box-shadow: inset 0 1px 1px rgba($blue, .075);
$input-focus-box-shadow:  0 0 0 0.2rem rgba($blue, .25);

$form-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
$form-select-bg-position: right 5px center;
$form-select-bg-size: 16px 12px;
$border-color: theme-color("primary");

$card-spacer-x: 0.5rem;

$btn-border-radius: 9999px;
$btn-border-radius-lg: 9999pxs;
$btn-border-radius-sm: 9999px;

$popover-header-bg: #e9d9ea;
$body-color: #332d37;

select.form-control {
  background-image: $form-select-indicator;
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  appearance: none;
}

.card {
  overflow: hidden;
}

.card-header {
  font-size: 16px;
}

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";
